exports.components = {
  "component---src-pages-archiv-js": () => import("./../../../src/pages/archiv.js" /* webpackChunkName: "component---src-pages-archiv-js" */),
  "component---src-pages-dokumenty-js": () => import("./../../../src/pages/dokumenty.js" /* webpackChunkName: "component---src-pages-dokumenty-js" */),
  "component---src-pages-formulare-js": () => import("./../../../src/pages/formulare.js" /* webpackChunkName: "component---src-pages-formulare-js" */),
  "component---src-pages-gdpr-oq-4-xc-8-f-0-s-js": () => import("./../../../src/pages/gdpr-oq4xc8f0s.js" /* webpackChunkName: "component---src-pages-gdpr-oq-4-xc-8-f-0-s-js" */),
  "component---src-pages-hospodareni-skoly-js": () => import("./../../../src/pages/hospodareni-skoly.js" /* webpackChunkName: "component---src-pages-hospodareni-skoly-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-organizace-js": () => import("./../../../src/pages/organizace.js" /* webpackChunkName: "component---src-pages-organizace-js" */),
  "component---src-pages-pro-rodice-js": () => import("./../../../src/pages/pro-rodice.js" /* webpackChunkName: "component---src-pages-pro-rodice-js" */),
  "component---src-pages-projekty-js": () => import("./../../../src/pages/projekty.js" /* webpackChunkName: "component---src-pages-projekty-js" */),
  "component---src-pages-stravovani-js": () => import("./../../../src/pages/stravovani.js" /* webpackChunkName: "component---src-pages-stravovani-js" */)
}

